import React from "react"

import { Button } from "components/anti/buttons/buttons"
import Seo from "components/seo"
import Layout from "components/layout"

import { CoreGroup } from "components/gutenberg/core-group"
import { CoreHeading } from "components/gutenberg/core-heading"
import { CoreList } from "components/gutenberg/core-list"
import { CoreParagraph } from "components/gutenberg/core-paragraph"
import { CoreShortcode } from "components/gutenberg/shortcode"

const BantuanDetail = ({ pageContext }) => {
  const blocks = pageContext.data.blocks
  const helpCategory = pageContext.data.help_categories.nodes[0]
  const helpCategorySlug = helpCategory.slug

  return (
    <Layout>
      <Seo title={pageContext?.data?.title} />
      <section className="py-main sc-bantuan-2">
        <div className="container mw-lg">
          <Button
            variant="link"
            className="btn-back-help ai-arrow-from-left"
            link={`/bantuan/${helpCategorySlug}`}
          >
            {helpCategorySlug}
          </Button>
          <div className="post-content">
            {blocks &&
              blocks.map((block, i) => {
                switch (block.name) {
                  case "core/group":
                    return <CoreGroup data={block} key={i} />
                  case "core/heading":
                    return <CoreHeading data={block} key={i} />
                  case "core/list":
                    return <CoreList data={block} key={i} />
                  case "core/paragraph":
                    return <CoreParagraph data={block} key={i} />
                  case "core/shortcode":
                    // prettier-ignore
                    return <CoreShortcode data={block} pageContext={pageContext} key={i} />
                  default:
                    return <div key={i} />
                }
              })}
          </div>
          <hr />
          {/* <div className="related-topic">
            <h5 className="text-muted">RELATED TOPIC</h5>
            <ul>
              <li>
                Can I downgrade to a lower tier if I withdraw my savings
                balance?
              </li>
              <li>How to top up my balance</li>
            </ul>
          </div> */}
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column mt-5">
          <p className="mb-0 text-muted">BUTUH BANTUAN LEBIH LANUT?</p>
          <Button variant="link" className="pt-0" link="/kontak-kami">
            HUBUNGI KAMI
          </Button>
        </div>
      </section>
    </Layout>
  )
}

export default BantuanDetail
